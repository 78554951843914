import { callApi } from "../service/Api";
import { ApiConstants } from "../constants/api";

import {
  getTreeData,
  getTreeNodeData,
  createFolderTreeData,
  updateSubscriptionFolderName,
  deleteSubscriptionFolder,
  updateSubscriptionTagName,
  deleteSubscriptionTag,
  updateSubscriptionFeedName,
  deleteSubscriptionFeed,
  createFeedTreeData,
  markAsRead,
  markAsReadCount,
  // markAsReadEntry,
  createTagTreeData,
} from "../selectors/subscription";
import { actionUpdateEntries } from "./entries";
import { callToast, isApiSuccess } from "../service/Utilities";
import {
  updateUserPref as updateUserPrefSelect,
  updateUserPrefMultiple,
  mapPrefKeyValue,
  updateUserPrefMultipleByName,
} from "../selectors/userPreferences";
import { updateUserPref, startSetUserInfo, startUpdateUserPref } from "./user";

export const subscription = (subscription = [], from = "unknown") => {
  // console.log('SET_SUBSCRIPTION called from', from, subscription)
  return {
    type: "SET_SUBSCRIPTION",
    subscription,
  };
};

export const startSubscription =
  ({ folder_id = false, sidebarRefresh = false } = {}) =>
  (dispatch, getState) =>
    new Promise((resolve, reject) => {
      // export const startSubscription = () => {
      //     return (dispatch, getState) => {

      const apiData = {
        url: ApiConstants.SUBSCRIPTION_LIST,
        token: getState().auth.token,
        data: {
          folder_id: !!folder_id ? folder_id : undefined,
        },
      };
      callApi(apiData)
        .then((res) => {
          if (isApiSuccess(res.status)) {
            // console.log('tets 110', res.data);
            // console.log('tets 111', getTreeData(res.data));
            // console.log('tets 112', subscription(getTreeData(res.data)));
            dispatch(subscription(getTreeData(res.data), "startSubscription"));
            if (sidebarRefresh) {
              console.log("SIDEBAR_REFRESH");
              dispatch(actionToDo({ type: "SIDEBAR_REFRESH" }));
            }
            resolve(res);
          } else {
            if (res.msg) {
              callToast({ type: "error", msg: res.msg });
            }
            throw new Error("Something went wrong");
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
      //     };
      // };
    });

export const startSubscriptionFolder =
  (folder_id, unread = 1) =>
  (dispatch, getState) =>
    new Promise((resolve, reject) => {
        // console.log("startSubscriptionFolder",folder_id,unread)
      const apiData = {
        url: ApiConstants.FOLDER_DETAIL,
        token: getState().auth.token,
        data: {
          folder_id: folder_id,
          unread,
        },
      };
      callApi(apiData)
        .then((res) => {
          if (isApiSuccess(res.status)) {
            if (!!res.data.folderFeed) {
              let children = getTreeNodeData(res.data.folderFeed) || [];
              let subscriptionArr = getState().main.subscription;
              const elementsIndex = subscriptionArr.findIndex(
                (obj) => obj.id == folder_id
              );
              let newSubscription = [...subscriptionArr];
              newSubscription[elementsIndex] = {
                ...newSubscription[elementsIndex],
                children,
              };
              // console.log('newSubscription', newSubscription)
              dispatch(
                subscription(newSubscription, "startSubscriptionFolder")
              );
              resolve();
            }
          } else {
            if (res.msg) {
              callToast({ type: "error", msg: res.msg });
            }
            reject(new Error("Something went wrong"));
          }
        })
        .catch((error) => {
          console.error("Error:", error);
          reject(new Error(error));
        });
    });

export const updateSubscription = (subscription = []) => ({
  type: "UPDATE_SUBSCRIPTION",
  subscription,
});

export const startUpdateSubscription = (data = "", subscription = []) => {
  return (dispatch, getState) => {
    let url;
    // if(data.mode && data.mode == "sort") {
    if (data.type && data.type == "sort") {
      url = ApiConstants.SUBSCRIPTION_SORT;
    } else if (data.type && data.type == "sort_folder") {
      url = ApiConstants.SUBSCRIPTION_SORT_FO;
    } else {
      url = ApiConstants.SUBSCRIPTION_MOVE;
    }

    const apiData = {
      url,
      token: getState().auth.token,
      method: "post",
      data,
    };
    callApi(apiData)
      .then((res) => {
        if (isApiSuccess(res.status)) {
          dispatch(updateSubscription(subscription));
          callToast({ type: "success", msg: "Saved Successfully" });
        } else {
          if (res.msg) {
            callToast({ type: "error", msg: res.msg });
          }
          throw new Error("Something went wrong");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
};

export const startCreateFolder = (folder_name) => {
  return (dispatch, getState) => {
    const apiData = {
      url: ApiConstants.FOLDER_CREATE,
      token: getState().auth.token,
      method: "POST",
      data: {
        folder_name,
      },
    };
    callApi(apiData)
      .then((res) => {
        if (isApiSuccess(res.status)) {
          let folderObj = createFolderTreeData({
            folder_name,
            folder_id: res.data.folder_id,
          });
          dispatch(subscription([folderObj, ...getState().main.subscription]));
          callToast({ type: "success", msg: "Folder Created Successfully" });
        } else {
          if (res.msg) {
            callToast({ type: "error", msg: res.msg });
          }
          throw new Error("Something went wrong");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
};

export const startRenameFolder =
  (folder_id, folder_name) => (dispatch, getState) =>
    new Promise((resolve, reject) => {
      const apiData = {
        url: ApiConstants.FOLDER_RENAME,
        token: getState().auth.token,
        method: "POST",
        data: {
          folder_id,
          folder_name,
        },
      };
      callApi(apiData)
        .then((res) => {
          if (isApiSuccess(res.status)) {
            dispatch(
              subscription(
                updateSubscriptionFolderName(
                  { folder_id, folder_name },
                  getState().main.subscription
                )
              )
            );

            const selectedSubscription = getState().main.selected;
            if (
              selectedSubscription &&
              selectedSubscription.type == "folder" &&
              selectedSubscription.id == folder_id
            ) {
              dispatch(
                startUpdateSelected({
                  ...selectedSubscription,
                  name: folder_name,
                })
              );
            }

            callToast({ type: "success", msg: "Renamed Successfully" });
            resolve();
          } else {
            if (res.msg) {
              callToast({ type: "error", msg: res.msg });
            }
            throw new Error("Something went wrong");
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    });

export const startDeleteFolder = (folder_id) => {
  return (dispatch, getState) => {
    const apiData = {
      url: ApiConstants.FOLDER_DELETE,
      token: getState().auth.token,
      method: "DELETE",
      data: {
        folder_id,
      },
    };
    callApi(apiData)
      .then((res) => {
        if (isApiSuccess(res.status)) {
          dispatch(
            subscription(
              deleteSubscriptionFolder(folder_id, getState().main.subscription)
            )
          );
          dispatch(
            goToViewAllIFOpenedRefresh({ type: "folder", id: folder_id })
          );
          // if(!!getState().main.selected && getState().main.selected.type=="folder" && getState().main.selected.id == folder_id) {
          //     dispatch(startUpdateSelected({type:"all",id:"",name:"View All"}))
          // }
          callToast({ type: "success", msg: "Unfollowing Folder" });
        } else {
          if (res.msg) {
            callToast({ type: "error", msg: res.msg });
          }
          throw new Error("Something went wrong");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
};

export const startRenameTag = (tag_id, tag_name) => {
  return (dispatch, getState) => {
    const apiData = {
      url: ApiConstants.TAG_RENAME,
      token: getState().auth.token,
      method: "POST",
      data: {
        tag_id,
        new_tag_name: tag_name,
      },
    };
    callApi(apiData)
      .then((res) => {
        if (isApiSuccess(res.status)) {
          dispatch(
            subscription(
              updateSubscriptionTagName(
                { tag_id, tag_name },
                getState().main.subscription
              )
            )
          );

          const selectedSubscription = getState().main.selected;
          if (
            selectedSubscription &&
            selectedSubscription.type == "tag" &&
            selectedSubscription.id == tag_id
          ) {
            dispatch(
              startUpdateSelected({ ...selectedSubscription, name: tag_name })
            );
          }

          callToast({ type: "success", msg: "Saved Successfully" });
        } else {
          if (res.msg) {
            callToast({ type: "error", msg: res.msg });
          }
          throw new Error("Something went wrong");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
};

export const goToViewAllIFOpenedRefresh =
  ({ type, id, name = undefined, parent_folder_id = false }) =>
  (dispatch, getState) => {
    if (!!getState().main.selected) {
      if (
        getState().main.selected.type == type &&
        getState().main.selected.id == id
      ) {
        if (["feed"].includes(type)) {
          // console.log('present on unfollowed site', type, id )
          dispatch(
            startUpdateSelected({
              ...getState().main.selected,
              is_following: false,
            })
          );
        } else {
          dispatch(
            startUpdateSelected({
              type: "all",
              key: "view_all",
              id: "view_all",
              name: "All Post",
            })
          );
        }
      } else {
        // refresh
        // dispatch(startUpdateSelected({
        //     ...getState().main.selected,
        //     timestamp: new Date().getTime()
        // }))
        if (["feed", "tag", "folder"].includes(type)) {
          dispatch(
            actionUpdateEntries({
              action: "DELETE",
              type,
              id,
              name,
            })
          );
        }
      }
    }
  };

export const startDeleteTag = (tag_id) => {
  return (dispatch, getState) => {
    const apiData = {
      url: ApiConstants.TAG_DELETE,
      token: getState().auth.token,
      method: "POST",
      data: {
        tag_id,
      },
    };
    callApi(apiData)
      .then((res) => {
        if (isApiSuccess(res.status)) {
          const tagObj = getState().main.subscription.find(
            ({ type, id }) => type == "tag" && id == tag_id
          );
          dispatch(
            subscription(
              deleteSubscriptionTag(tag_id, getState().main.subscription)
            )
          );
          dispatch(
            goToViewAllIFOpenedRefresh({
              type: "tag",
              id: tag_id,
              name: tagObj.title,
            })
          );
          // if(!!getState().main.selected && getState().main.selected.type=="tag" && getState().main.selected.id == tag_id) {
          //     dispatch(startUpdateSelected({type:"all",id:"",name:"View All"}))
          // }
          callToast({ type: "success", msg: "Saved Successfully" });
        } else {
          if (res.msg) {
            callToast({ type: "error", msg: res.msg });
          }
          throw new Error("Something went wrong");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
};

export const startRenameFeed = (feed_id, feed_name) => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const apiData = {
      url: ApiConstants.FEED_RENAME,
      token: getState().auth.token,
      method: "POST",
      data: {
        site_id: feed_id,
        site_name: feed_name,
      },
    };
    callApi(apiData)
      .then((res) => {
        if (isApiSuccess(res.status)) {
          dispatch(
            subscription(
              updateSubscriptionFeedName(
                { feed_id, feed_name },
                getState().main.subscription
              )
            )
          );

          const selectedSubscription = getState().main.selected;
          if (
            selectedSubscription &&
            selectedSubscription.type == "feed" &&
            selectedSubscription.id == feed_id
          ) {
            dispatch(
              startUpdateSelected({ ...selectedSubscription, name: feed_name })
            );
          }

          callToast({ type: "success", msg: "Renamed Successfully" });
          resolve();
        } else {
          if (res.msg) {
            callToast({ type: "error", msg: res.msg });
          }
          throw new Error("Something went wrong");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  });

export const startDeleteFeed =
  ({ feed_id, parent_folder_id = false }) =>
  (dispatch, getState) =>
    new Promise((resolve, reject) => {
      const apiData = {
        url: ApiConstants.FEED_DELETE,
        token: getState().auth.token,
        method: "DELETE",
        data: {
          site_id: feed_id,
          from_folder_id: parent_folder_id ? parent_folder_id : "",
        },
      };
      callApi(apiData)
        .then((res) => {
          if (isApiSuccess(res.status)) {
            dispatch(
              subscription(
                deleteSubscriptionFeed(
                  { feed_id, parent_folder_id },
                  getState().main.subscription
                )
              )
            );
            dispatch(
              goToViewAllIFOpenedRefresh({
                type: "feed",
                id: feed_id,
                parent_folder_id,
              })
            );
            // if(!!getState().main.selected && getState().main.selected.type=="feed" && getState().main.selected.id == feed_id) {
            //     dispatch(startUpdateSelected({type:"all",id:"",name:"View All"}))
            // }
            callToast({ type: "success", msg: "Removed" });
            resolve();
          } else {
            if (res.msg) {
              callToast({ type: "error", msg: res.msg });
            }
            reject(new Error("Something went wrong"));
          }
        })
        .catch((error) => {
          console.error("Error:", error);
          reject(new Error(error));
        });
    });

export const startAddFeed =
  ({ feed_id, showNewFolderModal = true }) =>
  (dispatch, getState) =>
    new Promise((resolve, reject) => {
      const apiData = {
        url: ApiConstants.FEED_ADD,
        token: getState().auth.token,
        method: "POST",
        data: {
          site_id: feed_id,
        },
      };
      callApi(apiData)
        .then((res) => {
          if (isApiSuccess(res.status)) {
            // console.log('startAddFeed success')
            const {
              type = "feed",
              site_name: name,
              site_id: id,
              unread: count = 0,
              site_domain: domain = false,
              site_image_url,
            } = res.data;
            let feedObj = createFeedTreeData({
              type,
              id,
              name,
              count,
              domain,
            });
            // console.log('feedObj', feedObj)
            // console.log('subscription', getState().main.subscription)
            dispatch(subscription([feedObj, ...getState().main.subscription]));

            if (showNewFolderModal) {
              // console.log('action OPEN_ADD_TO_FOLDER_MODAL')
              dispatch(
                actionToDo({
                  type: "OPEN_ADD_TO_FOLDER_MODAL",
                  // feedId:feed_id,
                  feedId: feedObj.id,
                  feedKey: feedObj.key,
                })
              );
            }

            callToast({ type: "success", msg: "Added Successfully" });
            resolve(res.data);
          } else {
            if (res.msg) {
              callToast({ type: "error", msg: res.msg });
            }
            reject(new Error("Something went wrong"));
          }
        })
        .catch((error) => {
          console.error("Error:", error);
          reject(new Error(error));
        });
    });

export const startAddSiteToFolder =
  ({
    site_id,
    folder_id = undefined,
    folder_name = undefined,
    fromFolder = false,
    toFolder = false,
    feed = false,
  }) =>
  (dispatch, getState) =>
    new Promise((resolve, reject) => {
      const apiData = {
        url: ApiConstants.FOLDER_ADD_SITE,
        token: getState().auth.token,
        method: "POST",
        data: {
          site_id,
          folder_id,
          folder_name,
          parent_folder_id: !!fromFolder ? fromFolder.id : undefined,
        },
      };
      callApi(apiData)
        .then((res) => {
          if (isApiSuccess(res.status)) {
            if (folder_name != undefined) {
              // add to new folder

              // console.log('add site to new folder - feed|toFo|fromFo', feed, folder_name, fromFolder)

              let folderObj = createFolderTreeData({
                folder_name,
                folder_id: res.data.folder_id,
              });

              dispatch(
                subscription([
                  folderObj,
                  ...deleteSubscriptionFeed(
                    {
                      feed_id: site_id,
                      parent_folder_id: fromFolder.id,
                    },
                    getState().main.subscription
                  ),
                ])
              );
            } else {
              // add to existing folder

              // console.log('add site to existing folder - feed|toFo|fromFo', feed, toFolder, fromFolder)

              // console.log('isseu', getState().main.subscription)
              // .map((item)=>!!fromFolder && item.key==fromFolder.key ? {
              //     ...item,
              //     count: item.count - feed.count,
              //     children: !!item.children ? item.children.filter(({key})=>key!=feed.key) : undefined
              // } : item )
              // .filter(item => !fromFolder && item.key != feed.key)
              // .map((item)=>!!toFolder && item.key==toFolder.key ? {
              //     ...item,
              //     count: item.count + feed.count,
              //     children: !!item.children ? [ ...item.children, feed ] : undefined
              // } : item ))

              let susbs = getState().main.subscription;
              if (!!fromFolder) {
                susbs = susbs
                  .map((item) =>
                    item.key == fromFolder.key
                      ? {
                          ...item,
                          count: item.count - feed.count,
                          children: !!item.children
                            ? item.children.filter(({ key }) => key != feed.key)
                            : undefined,
                        }
                      : item
                  )
                  .map((item) =>
                    !!toFolder && item.key == toFolder.key
                      ? {
                          ...item,
                          count: item.count + feed.count,
                          children: !!item.children
                            ? [...item.children, feed]
                            : undefined,
                        }
                      : item
                  );
              } else {
                susbs = susbs
                  .filter((item) => item.key != feed.key)
                  .map((item) =>
                    !!toFolder && item.key == toFolder.key
                      ? {
                          ...item,
                          count: item.count + feed.count,
                          children: !!item.children
                            ? [...item.children, feed]
                            : undefined,
                        }
                      : item
                  );
              }

              dispatch(subscription(susbs));

              // dispatch(subscription(
              //     getState().main.subscription
              //     .map((item)=>!!fromFolder && item.key==fromFolder.key ? {
              //         ...item,
              //         count: item.count - feed.count,
              //         children: !!item.children ? item.children.filter(({key})=>key!=feed.key) : undefined
              //     } : item )
              //     .filter(item => !fromFolder && item.key != feed.key)
              //     .map((item)=>!!toFolder && item.key==toFolder.key ? {
              //         ...item,
              //         count: item.count + feed.count,
              //         children: !!item.children ? [ ...item.children, feed ] : undefined
              //     } : item )
              // ));
            }

            callToast({ type: "success", msg: "Added To Folder Successfully" });
            resolve();
          } else {
            if (res.msg) {
              callToast({ type: "error", msg: res.msg });
            }
            reject(new Error("Something went wrong"));
          }
        })
        .catch((error) => {
          console.error("Error:", error);
          reject(new Error(error));
        });
    });

export const startAddFolder =
  ({ folder_id }) =>
  (dispatch, getState) =>
    new Promise((resolve, reject) => {
      const apiData = {
        url: ApiConstants.FOLDER_FOLLOW,
        token: getState().auth.token,
        method: "POST",
        data: {
          folder_id,
        },
      };
      callApi(apiData)
        .then((res) => {
          if (isApiSuccess(res.status)) {
            const { folder_name, folder_id, unread: count = 0 } = res.data;
            let folderObj = createFolderTreeData({
              folder_name,
              folder_id,
              count,
            });
            dispatch(
              subscription([folderObj, ...getState().main.subscription])
            );
            callToast({ type: "success", msg: "Followed Successfully" });
            resolve();
          } else {
            if (res.msg) {
              callToast({ type: "error", msg: res.msg });
            }
            reject(new Error("Something went wrong"));
          }
        })
        .catch((error) => {
          console.error("Error:", error);
          reject(new Error(error));
        });
    });

export const startMarkAsReadFeed =
  ({ feed_id, folders = [] }) =>
  (dispatch, getState) =>
    new Promise((resolve, reject) => {
      const apiData = {
        url: ApiConstants.MARK_READ_FEED,
        token: getState().auth.token,
        method: "POST",
        data: {
          feed_id,
        },
      };
      callApi(apiData)
        .then((res) => {
          if (isApiSuccess(res.status)) {
            dispatch(
              subscription(
                markAsRead({ feed_id, folders }, getState().main.subscription),
                "startMarkAsReadFeed"
              )
            );
            callToast({ type: "success", msg: "Marked as Read" });
            resolve();
          } else {
            if (res.msg) {
              callToast({ type: "error", msg: res.msg });
            }
          }
        })
        .catch((error) => {
          console.error("Error:", error);
          reject(new Error(error));
        });
    });

export const startMarkAsReadEntry =
  ({
    entry_id,
    feed_id: site_id,
    entry_created_at,
    is_read,
    folders = [],
    showToast = false,
  }) =>
  (dispatch, getState) =>
    new Promise((resolve, reject) => {
      const apiData = {
        url: is_read == 0 ? ApiConstants.MARK_READ : ApiConstants.MARK_UNREAD,
        token: getState().auth.token,
        method: "POST",
        data: {
          // time_range: "all"
          type: "entry",
          id: entry_id,
          // site_id,
          // mode: is_read == 0 ? "read" : "unread",
          // entry_created_at
        },
      };
      callApi(apiData)
        .then((res) => {
          if (isApiSuccess(res.status)) {
            dispatch(
              subscription(
                markAsRead(
                  { feed_id: site_id, folders, is_read },
                  getState().main.subscription
                ),
                "startMarkAsReadEntry"
              )
            );
            if (showToast) {
              callToast({
                type: "success",
                msg: `Marked as ${is_read == 0 ? "Read" : "Unread"}`,
              }); //disabled
            }
            resolve();
          } else {
            if (res.msg) {
              callToast({ type: "error", msg: res.msg });
            }
          }
        })
        .catch((error) => {
          console.error("Error:", error);
          reject(new Error(error));
        });
    });

export const startMarkAsReadEntryMultiple =
  ({ entries }) =>
  (dispatch, getState) =>
    new Promise((resolve, reject) => {
      const apiData = {
        url: ApiConstants.MARK_READ,
        token: getState().auth.token,
        method: "POST",
        data: {
          // time_range: "all"
          type: "entry",
          id: entries,
          // site_id,
          // mode: is_read == 0 ? "read" : "unread",
          // entry_created_at
        },
      };
      callApi(apiData)
        .then((res) => {
          if (isApiSuccess(res.status)) {
            // console.log(res);
            // entries.split(",").forEach(entry_id => {
            //     console.log('entry_id', entry_id)
            // });
            // dispatch(subscription(markAsReadEntry({entries:},getState().main.subscription)));
            // callToast({type:"success",msg:`Marked as ${is_read==0?"Read":"Unread"}`}); //disabled
            // console.log('startMarkAsReadEntryTime', markAsReadCount(res.data, getState().main.subscription))
            dispatch(
              subscription(
                markAsReadCount(res.data, getState().main.subscription),
                "startMarkAsReadEntryTime"
              )
            );
            resolve();
          } else {
            if (res.msg) {
              callToast({ type: "error", msg: res.msg });
            }
          }
        })
        .catch((error) => {
          console.error("Error:", error);
          reject(new Error(error));
        });
    });

export const startMarkAsReadEntryTime =
  ({ type = "all", id = false, time_range: range = "all", showToast = true }) =>
  (dispatch, getState) =>
    new Promise((resolve, reject) => {
      let data = {
        type,
        range,
      };
      data = ["feed", "folder", "pinboard"].includes(type)
        ? { ...data, id }
        : data;

      if (range == "all" && showToast) {
        callToast({ type: "success", msg: `Marking...`, autoClose: 1000 });
      }

      const apiData = {
        url: ApiConstants.MARK_READ,
        token: getState().auth.token,
        method: "POST",
        data,
      };
      callApi(apiData)
        .then((res) => {
          if (isApiSuccess(res.status)) {
            // console.log('ENTRY_MARK_READ', res)
            dispatch(
              actionUpdateEntries({
                action: "ENTRY_MARK_READ",
                data: res.data,
              })
            );
            // console.log('startMarkAsReadEntryTime', markAsReadCount(res.data, getState().main.subscription))
            dispatch(
              subscription(
                markAsReadCount(res.data, getState().main.subscription),
                "startMarkAsReadEntryTime"
              )
            );
            if (showToast) {
              // callToast({type:"success",msg:`Marked as ${is_read==0?"Read":"Unread"}`});
              callToast({
                type: "success",
                msg: `Marked as Read Successfully`,
              });
            }
            resolve();
          } else {
            if (res.msg) {
              callToast({ type: "error", msg: res.msg });
            }
          }
        })
        .catch((error) => {
          console.error("Error:", error);
          reject(new Error(error));
        });
    });

export const startUpdateSelected = (selected = {}) => {
  const { force = false, route = false } = selected;

  if (force == true) {
    // console.log('force called')
  }

  return {
    type: "UPDATE_SELECTED",
    selected: {
      ...selected,
      force,
      route: !!route
        ? route.split("/").includes(process.env.APP_BASENAME)
          ? route
          : `${process.env.APP_BASENAME}/${route}`
        : route,
    },
  };
};

export const startAddTag =
  ({ tag_name, entry_id }) =>
  (dispatch, getState) =>
    new Promise((resolve, reject) => {
      const apiData = {
        url: ApiConstants.TAG_ADD,
        token: getState().auth.token,
        method: "POST",
        data: {
          tag_name,
          entry_id,
        },
      };
      callApi(apiData)
        .then((res) => {
          if (isApiSuccess(res.status)) {
            const existingTagIds = getState()
              .main.subscription.filter((item) => item.type == "tag")
              .map((item) => item.id);
            const newTags = res.data.filter(
              (item) => !existingTagIds.includes(item.id)
            );

            let newTagTreeArr = [];
            newTags.map(({ id, name }) =>
              newTagTreeArr.push(createTagTreeData({ id, name }))
            );

            if (newTagTreeArr.length > 0) {
              dispatch(
                subscription([
                  ...newTagTreeArr,
                  ...getState().main.subscription,
                ])
              );
              callToast({ type: "success", msg: "Added Successfully" });
            }
            resolve();
          } else {
            if (res.msg) {
              callToast({ type: "error", msg: res.msg });
            }
            reject(new Error("Something went wrong"));
          }
        })
        .catch((error) => {
          console.error("Error:", error);
          reject(new Error(error));
        });
    });

export const updatePinboards = (pinboards = []) => ({
  type: "SET_PINBOARDS",
  pinboards,
});

export const startPinboards = () => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const apiData = {
      url: ApiConstants.PINBOARD_LIST,
      token: getState().auth.token,
    };
    callApi(apiData).then((res) => {
      if (isApiSuccess(res.status)) {
        dispatch(updatePinboards(res.data));
        resolve();
      } else {
        if (res.msg) {
          callToast({ type: "error", msg: res.msg });
        }
      }
    });
  });

export const startCreatePinboard = (name) => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const apiData = {
      url: ApiConstants.PINBOARD_CREATE,
      token: getState().auth.token,
      method: "POST",
      data: { pinboard_name: name },
    };
    callApi(apiData).then((res) => {
      if (isApiSuccess(res.status)) {
        dispatch(updatePinboards([res.data, ...getState().main.pinboards]));
        callToast({ type: "success", msg: "Created Successfully" });
        resolve();
      } else {
        if (res.msg) {
          callToast({ type: "error", msg: res.msg });
        }
      }
    });
  });

export const startDeletePinboards = (pinboard_id) => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const apiData = {
      url: ApiConstants.PINBOARD_DELETE,
      token: getState().auth.token,
      method: "DELETE",
      data: { pinboard_id },
    };
    callApi(apiData).then((res) => {
      if (isApiSuccess(res.status)) {
        dispatch(
          updatePinboards(
            getState().main.pinboards.filter(
              (item) => item.pinboard_id != pinboard_id
            )
          )
        );
        dispatch(
          actionUpdateEntries({
            action: "DELETE",
            type: "pinboard",
            id: pinboard_id,
          })
        );
        dispatch(
          goToViewAllIFOpenedRefresh({ type: "pinboard", id: pinboard_id })
        );
        callToast({ type: "success", msg: "Deleted Successfully" });
        resolve();
      } else {
        if (res.msg) {
          callToast({ type: "error", msg: res.msg });
        }
      }
    });
  });

export const startRenamePinboards =
  ({ pinboard_id, name }) =>
  (dispatch, getState) =>
    new Promise((resolve, reject) => {
      const apiData = {
        url: ApiConstants.PINBOARD_RENAME,
        token: getState().auth.token,
        method: "POST",
        data: { pinboard_id, pinboard_name: name },
      };
      callApi(apiData).then((res) => {
        if (isApiSuccess(res.status)) {
          dispatch(
            updatePinboards(
              getState().main.pinboards.map((item) =>
                item.pinboard_id == pinboard_id ? { ...item, name } : item
              )
            )
          );

          const selectedSubscription = getState().main.selected;
          if (
            selectedSubscription &&
            selectedSubscription.type == "pinboard" &&
            selectedSubscription.id == pinboard_id
          ) {
            dispatch(startUpdateSelected({ ...selectedSubscription, name }));
          }

          callToast({ type: "success", msg: "Saved Successfully" });
          resolve();
        } else {
          if (res.msg) {
            callToast({ type: "error", msg: res.msg });
          }
        }
      });
    });

// add remove
export const startManagePinboards =
  ({ pinboard_id, article_id, mode = "add", showToast = false }) =>
  (dispatch, getState) =>
    new Promise((resolve, reject) => {
      // console.log('startManagePinboards', pinboard_id,article_id, mode)

      const apiData = {
        url:
          mode == "remove"
            ? ApiConstants.PINBOARD_REMOVE
            : ApiConstants.PINBOARD_ADD,
        token: getState().auth.token,
        method: mode == "remove" ? "DELETE" : "POST",
        data: { pinboard_id, entry_id: article_id },
      };
      callApi(apiData).then((res) => {
        if (isApiSuccess(res.status)) {
          dispatch(
            actionUpdateEntries({
              action: mode == "remove" ? "DELETE" : "ADD",
              type: "pinboard",
              id: pinboard_id,
              entry_id: article_id,
            })
          );
          if (showToast) {
            callToast({
              type: "success",
              msg: `${mode == "remove" ? "Removed" : "Added"} Successfully`,
            });
          }
          resolve();
        } else {
          if (res.msg) {
            callToast({ type: "error", msg: res.msg });
          }
        }
      });
    });

export const actionToDo =
  (to_do = {}) =>
  (dispatch, getState) =>
    new Promise((resolve, reject) => {
      // console.log('actionToDo called')
      // console.log('to_do',to_do)
      dispatch({
        type: "ACTION_TO_DO",
        to_do: {
          ...to_do,
          timestamp: new Date().getTime(),
        },
      });
      resolve();
    });

export const startUnreadCount =
  ({ mode = "all", id: mainId = undefined, folder_id = undefined, token }) =>
  (dispatch, getState) =>
    new Promise((resolve, reject) => {
      // console.log('startUnreadCount api called')
      let showRedDotFlag = false;
      let subscriptionChangeFlag = false;
      let subs = getState().main.subscription;
      let selected = getState().main.selected;

      const { id: selectedId, type: selectedType } = selected;

      const apiData = {
        url: ApiConstants.UNREAD_COUNT,
        token,
        data: {
          mode,
          id: mainId,
          folder_id,
        },
      };
      callApi(apiData).then((res) => {
        if (isApiSuccess(res.status)) {
          // console.log('startUnreadCount', res.data)
          //   resolve(res.data);
          let updates = res.data;

          if (res.data.length > 0) {
            subs = subs.map((item) => {
              const { type, id, count, title } = item;

              let match = updates.findIndex(
                (element) => element.type == type && element.id == item.id
              );
              let newUnreadFlag = false;

              if (match >= 0 && updates[match].unread != count) {
                newUnreadFlag = true;
                // console.log('newUnread count set for', id, type, title)
                // console.log('old new', count, updates[match].unread)
              }

              if (!subscriptionChangeFlag && newUnreadFlag) {
                subscriptionChangeFlag = true;
              }

              if (
                newUnreadFlag &&
                !showRedDotFlag &&
                ["all", "feed", "folder"].includes(selectedType)
              ) {
                // console.log('startUnreadCount outer subs selected', selected, mainId)
                if (["feed", "folder"].includes(selectedType)) {
                  //
                  if (selectedId == item.id) {
                    // only selected
                    showRedDotFlag = true;
                  }
                } else {
                  // mode all
                  showRedDotFlag = true;
                }
                // console.log('showRedDotFlag true show red dot')
              }

              return newUnreadFlag
                ? {
                    ...item,
                    count:
                      item.id == updates[match].id
                        ? updates[match].unread
                        : item.count,
                  }
                : item;
            });

            // folder feeds inside
            if (!!folder_id) {
              let folder_ids = folder_id.toString().split(",");
              // console.log('folder_ids', folder_ids)
              subs = subs.map((item) => {
                const { type, id, count, title, children = false } = item;

                // console.log('sub item', item)

                let matchIndex = updates.findIndex(
                  (element) =>
                    element.type == "folder" &&
                    !!children &&
                    children.length > 0 &&
                    folder_ids.includes(element.id.toString())
                );
                // console.log('matchIndex', matchIndex)

                if (matchIndex >= 0) {
                  // console.log('inside match index', matchIndex)
                  let folderMatch = updates.find(
                    (item) => item.id == updates[matchIndex].id
                  );
                  let folderChildren = folderMatch.feeds;
                  // console.log('folderChildren', folderChildren)

                  // console.log('folderMatch', folderMatch)

                  return {
                    ...item,
                    count:
                      item.id == folderMatch.id
                        ? folderMatch.unread
                        : item.count,
                    children: children.map((el) => {
                      let mtch = folderChildren.findIndex(
                        (itm) => itm.id == el.id
                      );
                      // console.log('mtch', mtch)

                      let newUnreadInnerFlag = false;

                      if (
                        mtch >= 0 &&
                        folderChildren[mtch].unread != el.count
                      ) {
                        newUnreadInnerFlag = true;
                      }

                      if (!subscriptionChangeFlag && newUnreadInnerFlag) {
                        subscriptionChangeFlag = true;
                      }

                      if (
                        newUnreadInnerFlag &&
                        !showRedDotFlag &&
                        ["all", "feed", "folder"].includes(selectedType)
                      ) {
                        // console.log('startUnreadCount inside folder selected', selected, mainId)
                        if (["feed", "folder"].includes(selectedType)) {
                          //
                          if (selectedId == el.id) {
                            // only selected
                            showRedDotFlag = true;
                          }
                        } else {
                          // mode all
                          showRedDotFlag = true;
                        }
                        // console.log('showRedDotFlag true show red dot')
                      }

                      if (
                        mtch >= 0 &&
                        folderChildren[mtch].unread != el.count
                      ) {
                        // console.log('newUnread inside count set for', el.id, el.type, el.title)
                        // console.log('old new', el.count, folderChildren[mtch].unread)
                        return { ...el, count: folderChildren[mtch].unread };
                      } else {
                        // console.log('nthng new');
                        return el;
                      }
                    }),
                  };
                } else {
                  return item;
                }
              });
            }

            if (subscriptionChangeFlag) {
              dispatch(subscription(subs, "startUnreadCount"));
            }

            if (showRedDotFlag) {
              // console.log('dispatching new subs', subs)
              // dispatch(subscription(subs));
              dispatch(
                actionToDo({
                  type: "FAVICON_UPDATE",
                  unread: true,
                })
              );
            }
          }

          resolve();
        } else {
          if (res.msg) {
            callToast({ type: "error", msg: res.msg });
          }
        }
      });
      // .catch(error => {
      //     console.error('Error:', error);
      // });
    });

export const startOrganizeSort =
  ({ sequence_string, folder_id = undefined }) =>
  (dispatch, getState) =>
    new Promise((resolve, reject) => {
      const apiData = {
        url: ApiConstants.ORGANIZE_SORT,
        token: getState().auth.token,
        method: "POST",
        data: {
          sequence_string,
          folder_id,
        },
      };
      callApi(apiData).then((res) => {
        if (isApiSuccess(res.status)) {
          const { result = [], preferences = [] } = res.data;
          if (preferences.length > 0) {
            dispatch(
              updateUserPref(
                updateUserPrefMultipleByName(preferences, getState().user.pref)
              )
            );
          }
          dispatch(startSubscription({ sidebarRefresh: true }));
          resolve(res.data);
        } else {
          if (res.msg) {
            callToast({ type: "error", msg: res.msg });
          }
        }
      });
    });

export const startOrganizeDelete =
  ({ sequence_string, folder_id = undefined, showToast = true }) =>
  (dispatch, getState) =>
    new Promise((resolve, reject) => {
      const apiData = {
        url: ApiConstants.ORGANIZE_DELETE,
        token: getState().auth.token,
        method: "DELETE",
        data: {
          sequence_string,
          folder_id,
        },
      };
      callApi(apiData).then((res) => {
        if (isApiSuccess(res.status)) {
          // const { result=[], preferences=[] } = res.data;
          // if(preferences.length>0){
          //     dispatch(updateUserPref(updateUserPrefMultipleByName(preferences, getState().user.pref)));
          // }
          dispatch(startSubscription({ sidebarRefresh: true }));
          showToast &&
            callToast({ type: "success", msg: "Deleted Successfully" });
          resolve(res.data);
        } else {
          if (res.msg) {
            callToast({ type: "error", msg: res.msg });
          }
        }
      });
    });

export const startOrganizeMove =
  ({
    site_id,
    folder_id,
    parent_folder_id = undefined,
    folder_name = undefined,
    showToast = true,
  }) =>
  (dispatch, getState) =>
    new Promise((resolve, reject) => {
      const apiData = {
        url: ApiConstants.ORGANIZE_MOVE,
        token: getState().auth.token,
        method: "POST",
        data: {
          site_id,
          folder_id,
          parent_folder_id,
          folder_name,
        },
      };
      callApi(apiData).then((res) => {
        if (isApiSuccess(res.status)) {
          // const { result=[], preferences=[] } = res.data;
          // if(preferences.length>0){
          //     dispatch(updateUserPref(updateUserPrefMultipleByName(preferences, getState().user.pref)));
          // }
          dispatch(startSubscription({ sidebarRefresh: true }));
          showToast &&
            callToast({ type: "success", msg: "Moved Successfully" });
          resolve(res.data);
        } else {
          if (res.msg) {
            callToast({ type: "error", msg: res.msg });
          }
        }
      });
    });

export const startSwitchSubscription =
  ({ oldPlan, newPlan, showToast = true }) =>
  (dispatch, getState) =>
    new Promise((resolve, reject) => {
      const apiData = {
        url: ApiConstants.SUBSCRIPTION_SWITCH,
        token: getState().auth.token,
        method: "POST",
        data: {
          old_plan: oldPlan,
          plan: newPlan,
        },
      };
      callApi(apiData).then((res) => {
        if (isApiSuccess(res.status)) {
          // const { preferences=[] } = res.data;
          // if(preferences.length>0){
          //     dispatch(updateUserPref(updateUserPrefMultipleByName(preferences, getState().user.pref)));
          // }
          dispatch(startSetUserInfo());
          dispatch(actionToDo({ type: "REFRESH_BILLING_API" }));
          showToast &&
            callToast({
              type: "success",
              msg: "Switched Subscription Successfully",
            });
          resolve(res.data);
        } else {
          if (res.msg) {
            callToast({ type: "error", msg: res.msg });
          }
          reject(new Error("Something went wrong"));
        }
      });
    });

export const startChangeSubscription =
  ({ oldPlan, newPlan, showToast = true }) =>
  (dispatch, getState) =>
    new Promise((resolve, reject) => {
      const apiData = {
        url: ApiConstants.SUBSCRIPTION_CHANGE,
        token: getState().auth.token,
        method: "POST",
        data: {
          old_plan: oldPlan,
          new_plan: newPlan,
        },
      };
      callApi(apiData).then((res) => {
        if (isApiSuccess(res.status)) {
          // const { preferences=[] } = res.data;
          // if(preferences.length>0){
          //     dispatch(updateUserPref(updateUserPrefMultipleByName(preferences, getState().user.pref)));
          // }
          dispatch(startSetUserInfo());
          dispatch(actionToDo({ type: "REFRESH_BILLING_API" }));
          showToast &&
            callToast({
              type: "success",
              msg: "Changed Subscription Successfully",
            });
          resolve(res.data);
        } else {
          if (res.msg) {
            callToast({ type: "error", msg: res.msg });
          }
          reject(new Error("Something went wrong"));
        }
      });
    });

export const addCustomFeedToFolder =
  (folder_id, context_id, feed_url,title) => 
    (dispatch, getState) =>
    new Promise((resolve, reject) => {
      const ApiData = {
        url: "https://api2.devteams.feedspot.com/v2/spotlight/custom_feed",
        token: getState().auth.token,
        method: "POST",
        data: {
          folder_id,
          context_id,
          feed_url,
        },
      };
      callApi(ApiData).then((res) => {
        if (isApiSuccess(res.status)) {
        //   dispatch(startSubscription({ sidebarRefresh: true }));
        dispatch({type:"EXPAND_CUSTOM_FEED",folder_id,feed_id:res?.data?.feed_id,title,toExpand:true})
        resolve(res.data.feed_id);
        } else {
          if (res.msg) {
            callToast({ type: "error", msg: res.msg });
          }
          reject(new Error("Something went wrong"));
        }
      });
    });

export const addNote = (entry_id,note)  => (dispatch,getState) => new Promise((resolve,reject) => {
  const ApiData = {
    url: ApiConstants.ADD_NOTE,
    token: getState().auth.token,
    method: "POST",
    data: {
      entry_id,
      note,
    },
  };
  callApi(ApiData).then((res) => {
    if (isApiSuccess(res.status)) {
    //   dispatch(startSubscription({ sidebarRefresh: true }));
    dispatch(actionUpdateEntries({action:"NOTES_ADD",note_data:res.data,entry_id}))
    resolve();
    } else {
      if (res.msg) {
        
        callToast({ type: "error", msg: res.msg });
      }
      reject(new Error("Something went wrong"));
    }
  });
})

export const editNote = (entry_id,note,note_id) => (dispatch,getState) => new Promise((resolve,reject) => {
  const ApiData = {
    url: ApiConstants.EDIT_NOTE,
    token: getState().auth.token,
    method: "POST",
    data: {
      note_id,
      note,
    },
  };
  callToast({msg:"Updating..."})
  callApi(ApiData).then((res) => {
    if (isApiSuccess(res.status)) {
    //   dispatch(startSubscription({ sidebarRefresh: true }));
    dispatch(actionUpdateEntries({action:"NOTES_UPDATE",note:res.data.note,entry_id,note_id}))
    callToast({msg:"Updated Successfully"})
    resolve(res);
    } else {
      if (res.msg) {
        callToast({ type: "error", msg: res.msg });
      }
      reject(new Error("Something went wrong"));
    }
  });
})


export const deleteNote = (entry_id,note_id) => (dispatch,getState) => new Promise((resolve,reject) => {
  const ApiData = {
    url: ApiConstants.DELETE_NOTE,
    token: getState().auth.token,
    method: "POST",
    data: {
      note_id,
    },
  };
  callToast({msg:"Deleting..."})
  callApi(ApiData).then((res) => {
    if (isApiSuccess(res.status)) {
    dispatch(actionUpdateEntries({action:"NOTES_DELETE",entry_id,note_id}))
    callToast({msg:"Deleted Successfully!"})
    resolve(res);
    } else {
      if (res.msg) {
        callToast({ type: "error", msg: res.msg });
      }
      reject(new Error("Something went wrong"));
    }
  });
})

export const addHighlight = (entry_id,start_index,end_index,highlighted_text,version="1") => (dispatch,getState) => new Promise((resolve,reject) => {

  const ApiData = {
    url:ApiConstants.ADD_HIGHLIGHT,
    token:getState().auth.token,
    method:"POST",
    data: {
      entry_id,
      start_index,
      end_index,
      highlighted_text,
      version
    }
  }
  callApi(ApiData).then((res) =>{
    if(isApiSuccess(res.status)){
      dispatch(actionUpdateEntries({action:"HIGHLIGHT_ADD",highlight_data:res.data,entry_id}))
      resolve(res);
    }else{
      if(res.msg){
        // console.log(res.msg)
        
        if(res.msg=="Please try after sometime!(Already highlighted!)"){
          dispatch(actionUpdateEntries({action:"SHOW_REFRESH_MODAL",entry_id}))
        }else{
          callToast({type:"error",msg:res.msg})
        }
        
      }
      reject(new Error("Something went wrong"))
    }
  })
})

export const highlightDelete = (highlight_id,entry_id) => (dispatch,getState) => new Promise((resolve,reject) => {
  const ApiData = {
    url:ApiConstants.DELETE_HIGHLIGHT,
    method:"POST",
    token:getState().auth.token,
    data:{
      highlight_id
    }
  }
  callApi(ApiData).then((res) => {
    if(isApiSuccess(res.status)){
      dispatch(actionUpdateEntries({action:"HIGHLIGHT_DELETE",highlight_id,entry_id}));
      resolve(res);
    }
    else{
      if(res.msg){
        callToast({type:"error",msg:res.msg})
      }
      reject(new Error("Something went wrong"))
    }
  })
})
