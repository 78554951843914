import { v4 as uuidv4 } from 'uuid';

export const getTreeData = (subscription = []) => {
    // console.log('getTreeData', subscription)

    return subscription.map(obj => {

      let rObj = {}
      rObj.id = obj.id;
      rObj.key = uuidv4();
      rObj.typeid = `${obj.type}-${obj.id}`;
      rObj.title = obj.name;
      rObj.isLeaf = obj.type !== "folder";
      rObj.type = obj.type;
      rObj.count = obj.unread;
      // rObj.icon=this.Icon
      
      if(obj.type == "feed" && !!obj.domain) {
        rObj.feed_image_url = `${process.env.CDN_GOOGLE_FAVICON_ENDPOINT}favicons?alt=feed&domain=${obj.domain}`;
      }
      if(obj.type=="folder" && !!obj.folderFeed && Array.isArray(obj.folderFeed)) {
      // //   // const child = obj.children;
      // //   // rObj.children = (child) => this.getTreeData(child);
        rObj.children = getTreeNodeData(obj.folderFeed || []);
      }
      // console.obj('test rObj', rObj);
      return rObj
   })

}

export const getTreeNodeData = (folderFeed = []) => {

  return folderFeed.map((obj) => {
    // console.log(obj)
    obj.type = "feed";
    let rObj = {}
    rObj.id = obj.feed_id;
    rObj.key =`feeds_${obj.feed_id}`;
    rObj.typeid = `${obj.type}-${obj.feed_id}`;
    rObj.title = obj.feed_name;
    rObj.isLeaf = obj.type !== "folder";
    rObj.type = obj.type;
    rObj.count = obj.unread;
    // rObj.icon=this.Icon
    if(obj.type == "feed" && !!obj.feed_domain) {
      rObj.feed_image_url = `${process.env.CDN_GOOGLE_FAVICON_ENDPOINT}favicons?alt=feed&domain=${obj.feed_domain}`;
    }
    return rObj;
  });
}

export const getSubcriptionSortData = (subscriptions = []) => {
  return subscriptions
    .map(subscription => subscription.typeid)
    .join(",");
}

export const createFolderTreeData = ({type="folder",folder_id, folder_name, count=0} = {}) => {
    let rObj = {};
    rObj.id = folder_id;
    rObj.key = uuidv4();
    rObj.typeid = `${type}-${folder_id}`;
    rObj.title = folder_name;
    rObj.isLeaf = type !== "folder";
    rObj.type = type;
    rObj.count = count;
    return rObj;
}

export const createFeedTreeData = ({type="feed",id, name, count=0, domain=false} = {}) => {
  let rObj = {};
  rObj.id = id;
  rObj.key = uuidv4();
  rObj.typeid = `${type}-${id}`;
  rObj.title = name;
  rObj.isLeaf = type !== "folder";
  rObj.type = type;
  rObj.count = count;
  if(type == "feed" && !!domain) {
    rObj.feed_image_url = `${process.env.CDN_GOOGLE_FAVICON_ENDPOINT}favicons?alt=feed&domain=${domain}`;
  }
  return rObj;
}

export const createTagTreeData = ({type="tag",id, name, count=0} = {}) => {
  let rObj = {};
  rObj.id = id;
  rObj.key = uuidv4();
  rObj.typeid = `${type}-${id}`;
  rObj.title = name;
  rObj.isLeaf = type !== "folder";
  rObj.type = type;
  rObj.count = count;
  return rObj;
}

export const updateSubscriptionFolderName = ({folder_id, folder_name}, subscriptions = []) => {
  return subscriptions
  .map((item, index) => item.id == folder_id ? { ...item, title: folder_name } : item)
}

export const deleteSubscriptionFolder = (folder_id, subscriptions = []) => {
  return subscriptions
  .filter(item => item.id != folder_id)
}

export const updateSubscriptionTagName = ({tag_id, tag_name}, subscriptions = []) => {
  return subscriptions
  .map((item, index) => item.id == tag_id ? { ...item, title: tag_name } : item)
}

export const deleteSubscriptionTag = (tag_id, subscriptions = []) => {
  return subscriptions
  .filter(item => item.id != tag_id)
}

export const updateSubscriptionFeedName = ({feed_id, feed_name}, subscriptions = []) => {
  return subscriptions
  .map((item, index) => {
    if(item.id == feed_id) {
      return { ...item, title: feed_name }
    }
    else if(!!item.children) {
      return {
        ...item, 
        children: item.children.map((subItem) => subItem.id == feed_id ? { ...subItem, title: feed_name } : subItem)
      }
    }
    else {
      return item;
    }
  })
}

export const deleteSubscriptionFeed = ({feed_id, parent_folder_id, forceDelete=true}, subscriptions = []) => {
  if(forceDelete) {
    return subscriptions
    .filter(item => item.id != feed_id)
    .map(item => {
      if(item.type == "folder" && !!item.children)
      {
        return {
          ...item, 
          children: item.children.filter(subItem => subItem.id != feed_id)
        }
      }
      else {
        return item;
      }
    })
  }
  else {
    if(parent_folder_id) {
      return subscriptions
      .map(item => {
        if(item.id == parent_folder_id && !!item.children)
        {
          return {
            ...item, 
            children: item.children.filter(subItem => subItem.id != feed_id)
          }
        } 
        else {
          return item;
        }
      }); 
    }
    else {
      return subscriptions
      .filter(item => item.id != feed_id)
    }
  }
}

// export const getSubscriptionDetailsByKeyx = (key, subscriptions = []) => {
//   let level1 = subscriptions.find(item => {
//     console.log(item)
//     if(item.key === key) {
//       return true;
//     }
//     else if(!!item.children) {
//       return getSubscriptionDetailsByKey(key, item.children);
//     }
//     return false;
//   });
//   if(level1 && level1.key !== key) {

//   }
//   return level1;
// }

export const getSubscriptionDetailsByTypeId = (type, id, subscriptions = [], searchChildren = true) => {
  for (let item in subscriptions) {
    if(subscriptions[item].id == id && subscriptions[item].type === type) {
      return { ...subscriptions[item], parent:false };
    }
    if(searchChildren && !!subscriptions[item].children) {
      let child = getSubscriptionDetailsByTypeId(type, id, subscriptions[item].children , false);
      if(!!child) {
        const { children, ...parent } = subscriptions[item];
        return {...child, parent };
      }
    }
  }
  return false;
}

export const getSubscriptionDetailsByKey = (key, subscriptions = [], searchChildren = true) => {
  for (let item in subscriptions) {
    if(subscriptions[item].key === key) {
      return { ...subscriptions[item], parent:false };
    }
    if(searchChildren && !!subscriptions[item].children) {
      let child = getSubscriptionDetailsByKey(key, subscriptions[item].children , false);
      if(!!child) {
        const { children, ...parent } = subscriptions[item];
        return {...child, parent };
      }
    }
  }
  return false;
}

export const sortSubscriptions = ({ sortType, loop=true }, subscriptions=[]) => {
  // console.log('sortSubscriptions', sortType)
  let type = sortType.toString().toLowerCase();
  if (['oldest','recent'].indexOf(type) === -1){
    return subscriptions;
  }
  else {
    let sortPoint = ['tag','feed','folder'];
    let countPoint = (count) => count > 0 ? 1 : 0;

    let sortLogic = (a, b) => {
      if (type === 'oldest') {
        if (sortPoint.indexOf(a.type) > sortPoint.indexOf(b.type)) return -1;
        if (sortPoint.indexOf(a.type) < sortPoint.indexOf(b.type)) return 1;

        if (countPoint(a.count) > countPoint(b.count)) return -1;
        if (countPoint(a.count) < countPoint(b.count)) return 1;

        if (a.title.toUpperCase() > b.title.toUpperCase()) return 1;
        if (a.title.toUpperCase() < b.title.toUpperCase()) return -1;
      } else if (type === 'recent') {
        if (sortPoint.indexOf(a.type) > sortPoint.indexOf(b.type)) return -1;
        if (sortPoint.indexOf(a.type) < sortPoint.indexOf(b.type)) return 1;

        if (countPoint(a.count) > countPoint(b.count)) return -1;
        if (countPoint(a.count) < countPoint(b.count)) return 1;
        
        if (a.title.toUpperCase() > b.title.toUpperCase()) return -1;
        if (a.title.toUpperCase() < b.title.toUpperCase()) return 1;
      }

      //  folder feed along with unread on top
      // if (type === 'oldest') {
      //   if (sortPoint.indexOf(a.type)+(a.count>0?1:0) > sortPoint.indexOf(b.type)+(b.count>0?1:0)) return -1;
      //   if (sortPoint.indexOf(a.type)+(a.count>0?1:0) < sortPoint.indexOf(b.type)+(b.count>0?1:0)) return 1;

      //   if (a.title.toUpperCase() > b.title.toUpperCase()) return 1;
      //   if (a.title.toUpperCase() < b.title.toUpperCase()) return -1;
      // } else if (type === 'recent') {
      //   if (sortPoint.indexOf(a.type)+(a.count>0?1:0) > sortPoint.indexOf(b.type)+(b.count>0?1:0)) return -1;
      //   if (sortPoint.indexOf(a.type)+(a.count>0?1:0) < sortPoint.indexOf(b.type)+(b.count>0?1:0)) return 1;
        
      //   if (a.title.toUpperCase() > b.title.toUpperCase()) return -1;
      //   if (a.title.toUpperCase() < b.title.toUpperCase()) return 1;
      // }
    }
    let sortedSubs = subscriptions.sort(sortLogic)
    if(loop){
      sortedSubs = sortedSubs.map(item => !!item.children ? {...item, children: sortSubscriptions({sortType, loop:false}, item.children)} : item);
    }
    return sortedSubs;
  }
}

export const manageViewSubscription = ({ viewPref }, subscriptions=[]) => {
  if (['updated'].indexOf(viewPref.toString().toLowerCase()) === -1){
    return subscriptions;
  }
  else {
    return subscriptions
    .filter(item => (item.count > 0 || item.type == "tag"))
    .map(item => !!item.children ? {...item, children:item.children.filter(item => item.count > 0)} : item)
  }
}

export const markAsRead = ({feed_id, is_read, folders=[]}, subscriptions = []) => {
  let calclogic = is_read == 0 ? -1 : 1;
  return subscriptions
  .map(item => {
    if(item.type == "folder" && folders.map(folder_id => Number(folder_id)).includes(Number(item.id))) {
      if(!!item.children) {
        return {
          ...item, 
          // count: item.count>0 ? item.count-1 : item.count,
          count: item.count + calclogic,
          // children: item.children.map(subItem => subItem.id == feed_id && subItem.count>0 ? {...subItem, count:subItem.count-1} : subItem )
          children: item.children.map(subItem => subItem.id == feed_id ? {...subItem, count:subItem.count+calclogic} : subItem )
        }
      }
      else {
        return {
          ...item,
          // count: item.count>0 ? item.count-1 : item.count,
          count: item.count + calclogic,
        }
      }
    }
    // else if(item.type == "folder" && !!item.children)
    // {
    //   return {
    //     ...item, 
    //     children: item.children.map(subItem => subItem.id == feed_id && subItem.count>0 ? {...subItem, count:subItem.count-1} : subItem )
    //   }
    // }
    // else if(item.type == "feed" && item.id == feed_id  && item.count>0)
    else if(item.type == "feed" && item.id == feed_id)
    {
      return {
        ...item,
        count: item.count+calclogic
      }
    }
    else {
      return item;
    }
  })
}

export const markAsReadMultiple = ({feed_id, is_read, folders=[]}, subscriptions = []) => {
  let calclogic = is_read == 0 ? -1 : 1;
  return subscriptions
  .map(item => {
    if(item.type == "folder" && folders.map(folder_id => Number(folder_id)).includes(Number(item.id))) {
      if(!!item.children) {
        return {
          ...item, 
          // count: item.count>0 ? item.count-1 : item.count,
          count: item.count + calclogic,
          // children: item.children.map(subItem => subItem.id == feed_id && subItem.count>0 ? {...subItem, count:subItem.count-1} : subItem )
          children: item.children.map(subItem => subItem.id == feed_id ? {...subItem, count:subItem.count+calclogic} : subItem )
        }
      }
      else {
        return {
          ...item,
          // count: item.count>0 ? item.count-1 : item.count,
          count: item.count + calclogic,
        }
      }
    }
    // else if(item.type == "folder" && !!item.children)
    // {
    //   return {
    //     ...item, 
    //     children: item.children.map(subItem => subItem.id == feed_id && subItem.count>0 ? {...subItem, count:subItem.count-1} : subItem )
    //   }
    // }
    // else if(item.type == "feed" && item.id == feed_id  && item.count>0)
    else if(item.type == "feed" && item.id == feed_id)
    {
      return {
        ...item,
        count: item.count+calclogic
      }
    }
    else {
      return item;
    }
  })
}

export const markAsReadCount = (updates=[], subscriptions = []) => {

  let folder_ids = updates.map(item => Number(item.folder_id));
  let feed_ids = updates.map(item => Number(item.feed_id));

  // console.log('folder_ids',folder_ids )
  // console.log('feed_ids',feed_ids )

  let data =  subscriptions
  .map(item => {
    if(item.type == "folder" && folder_ids.includes(Number(item.id))) {
      let folder_count = updates.filter(el => el.folder_id == item.id).reduce((accumulator, currentValue) => {
        return accumulator + Number(currentValue.unread)
      }, 0) || 0;
      if(!!item.children) {
        return {
          ...item, 
          count: folder_count,
          children: item.children.map(subItem => feed_ids.includes(Number(subItem.id)) ? {...subItem, count:Number(updates.find( ({ feed_id }) => feed_id == subItem.id ).unread || 0)} : subItem )
        }
      }
      else {
        return {
          ...item,
          count: folder_count,
        }
      }
    }
    else if(item.type == "feed" && feed_ids.includes(Number(item.id))) {
      return {
        ...item, 
        count: Number(updates.find( ({ feed_id }) => feed_id == item.id ).unread || 0),
      }
    }
    else {
      return item;
    }

  });

  return data;

}


//   return subscriptions
//   .map(item => {
//     if(item.type == "folder" && folders.includes(item.id)) {


//       let feed_ids = updates.map(item => Number(item.feed_id));
//     // let entry_ids = updates.map(item => item.entry_id);

//     // console.log('test0', feed_ids );
//     // console.log('test1', feed_ids.includes(Number("22494")) );
//     // console.log('test2', updates.find( ({ feed_id }) => feed_id == Number("22494") ));
//     // console.log('test3', Number("22494") <= Number(updates.find( ({ feed_id }) => feed_id == "22494").entry_id) );

//     return entries
//     .map((item) => 
    
    
//     folder_ids.includes(Number(item.feed_id)) && Number(item.entry_id) <= Number(updates.find( ({ feed_id }) => feed_id == item.feed_id ).entry_id) ? {
//         ...item,
//         preferences: {
//             ...item.preferences,
//             // is_read: !item.preferences.is_read
//             is_read: 1
//         }
//     } : item )

// }

export const getTotalUnreadCount = ({subscriptions = [], type="all", id=false}) => {

  // console.log('getTotalUnreadCount', type)
  let subs = [];
  if(type=="all") {
    subs = subscriptions;
  }
  else if(type=="folder" && !!id ) {
    subs = subscriptions.filter(item => item.id == id && item.type == "folder");
  }
  else if(type=="feed" && !!id ) {
    subs = subscriptions.filter(item => item.id == id && item.type == "feed");
    if(subs.length==0) {
      subs = subscriptions
      .filter(item => item.type == "folder" && !!item.children)
      .map(item => item.children )
      .reduce(function (accumulator, current) {
        return [...accumulator, ...current ];
      }, [])
      .filter(item => item.id == id && item.type == "feed");
    }
  }

  return subs.reduce(function (accumulator, currentValue) {
    return accumulator + currentValue.count
  }, 0);
}